
<div class=""  style="opacity: 1;">

  <div class="ng-tns-c20-0 toast-title ng-star-inserted" *ngIf="title" >
    <!-- Connection error  -->
    {{ title }}
  </div>

  <!-- Error Message  -->
  <div >
    {{ problem.message }}
  </div>


  <!-- ProblemsDto[] -->

  <div *ngFor="let p of problem.problems">
    <br />
    <div *ngIf="p.message">{{ p.message }}</div>
    <div *ngIf="p.code">
      @ {{ p.code }}</div>
    <div *ngIf="p.propertyPaths && p.propertyPaths.length > 0 && p.propertyPaths.join('').length > 0 ">
      Property  : {{ p.propertyPaths.join('.') }}
    </div>
    
  </div>

  <hr />
  <!-- Error Code -->
   <div *ngIf="problem.code">
    Error Code : {{ problem.code }}
  </div>

  <!-- Correlation Id -->
  <!-- Do not show correlation / log id -> validation errors are not sent to log! -->
  <!-- <div *ngIf="problem.correlationId">
    Error Log Id : {{ problem.correlationId }}
  </div> -->

  <button class="btn btn-light mt10 btn-sm" (click)="CopyToClipboard($event)">Copy Error</button>


</div>


<!--
    code?: string | undefined;
    message?: string | undefined;
    correlationId?: string | undefined;
    problems?: ProblemDto[] | undefined;
  -->
