
<div class=""  style="opacity: 1;">

  <div class="ng-tns-c20-0 toast-title ng-star-inserted" *ngIf="title" >
    <!-- Connection error  -->
    {{ title }}
  </div>

  <!-- Error Message  -->
  <div >
    {{ problem.message }}
  </div>

  <!-- Correlation Id -->
  <div *ngIf="problem.correlationId">
    Error Log Id : {{ problem.correlationId }}
  </div>

  <div *ngIf="code">
    HTTP Response Code : {{ code }}
  </div>

  <button class="btn btn-light mt10 btn-sm" (click)="CopyToClipboard($event)">Copy Error</button>


</div>


<!--
Example Button Click

<a class="btn btn-pink btn-sm" (click)="action($event)">
    {{ undoString }}
</a>
  -->
