import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { RoutineFailureResponseDto } from '../http-error-models';

@Component({
  selector: '[routine-failure-error-response-toast]',
  templateUrl : './routine-failure-response-toast.component.html'
})
export class RoutineFailureResponseToastComponent extends Toast {

  @Input() problem : RoutineFailureResponseDto;
  
  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
 
  
  CopyToClipboard(event: Event) {

    let json = {
      "Title" : this.title ?? "",
      "Message" : this.problem.message,
      "Problems" : this.problem ?.problems,
      "Error Code" : this.problem ?.code,
      "Error Type" : this.problem.failureType,
    }

    // remove null or empy keys
    Object.keys(json).forEach((k) => json[k] == null && delete json[k]);


    var clippy = JSON.stringify( json, null, 4);

    navigator.clipboard.writeText( clippy );
    // good browser support, per https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText

    event.stopPropagation();
    return false;
  }
}