import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loading: boolean = false;

  private count=0;
  private loader=new BehaviorSubject<string>('');

  constructor() { }

  getLoaderObserver():Observable<string>{
    return this.loader.asObservable();
  }

  requestStarted(){
    if(++this.count===1){
      this.loader.next('start');
    }
  }

  requestEnded(){
    if(this.count===0 || --this.count==0){
      this.loader.next('stop');
    }
  }

  resetLoader(){
    this.count=0;
    this.loader.next('stop');
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  getLoading(): boolean {
    return this.loading;
  }
}