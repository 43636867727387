import { Injectable, Injector } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { UserService } from "@shared/services/user/user.service";
import { MessageTypeEnum, WindowEventsService } from "@shared/services/window-events.service";
import { throttleTime } from "rxjs";
import { v4 as uuidv4 } from 'uuid';
import { TokenManager } from "./token-manager.service";
import { ToastrModule, ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root'})
export class ApiCredentialsService {

    username:string;
    ngAuthToken:string;
    correlationId : string;


    constructor(private router : Router, private injector: Injector, 
        private tokenManager : TokenManager, private windowEvents : WindowEventsService,
        private toastr : ToastrService ) {
        // take authentication credentials from querystirng
        this.correlationId = uuidv4();     

        //on load, get user id, auth token from local session!
        this.username    = localStorage.getItem('user');
        this.ngAuthToken = localStorage.getItem('auth');

        this.router.events.pipe(
            throttleTime( 60000, null, { leading : true })
        ).subscribe(event =>{
            this.tokenManager.continueRenewalRequests();
         })

    }

    setCredentials( username : string, ngAuthToken : string ){
        this.username = username;
        this.ngAuthToken = ngAuthToken;
        localStorage.setItem('user', username);
        localStorage.setItem('auth', ngAuthToken);
        this.tokenManager.startRenewalRequests();

        // UserService -> Clear Cache 
        // Note : Avoid circular depenency via service locator antipattern
        let service = this.injector.get( UserService );
        service.ClearCache();
    }

    logout() {        
        localStorage.removeItem('auth');
        localStorage.removeItem('user');
        this.tokenManager.stopRenewalRequests();
        this.windowEvents.SendMessageToAll( MessageTypeEnum.Logout );
    }

    logout_fromWindowEventsService(){
        // dont redirect away from demo/dev menu page because its annoying
        if ( window.location.pathname == '/web-ui/') 
            return;

        this.tokenManager.stopRenewalRequests();
        this.router.navigate(['auth','logout-done']);
        this.toastr.error("Your Session Has Expired. Please login again");

        if(document.querySelectorAll(".e-dlg-container")[0] != undefined){
            let d = document.querySelectorAll(".e-dlg-container")[0] as HTMLElement;
            d.style.display="none";
        }
    }


}

