import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponseService } from './http-error-response.service';

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor{
     constructor(
         public service : HttpErrorResponseService 
        ) { }
    
     //todo : subject, subscription
    // also : 
    // shared folder project sturcture! https://stackoverflow.com/questions/52933476/angular-project-structure-best-practice
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{        
        return next.handle(req)
        .pipe( catchError( this.emitError.bind(this)  ));
    }

    public emitError(error: HttpErrorResponse ){
        this.service.Send( error );
        //throwError(() => error);
    }

      
}



