import { Component, Input } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { ErrorResponse } from '../http-error-models';

@Component({
  selector: '[error-response-toast]',
  templateUrl : './error-response-toast.component.html'
})
export class ErrorResponseToastComponent extends Toast {
  // used for demo purposes
  undoString = 'undo';

  @Input() problem : ErrorResponse;
  @Input() code : number;
  
  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }


  
  CopyToClipboard(event: Event) {

    let json = {
      "Title" : this.title ?? "",
      "Problem" : this.problem ?.message,
      "Error Log Id" : this.problem ?.correlationId,
      "HTTP Response Code" : this ?. code ?? "",
      "Error Outlet" : "Response Error"
    };

    // remove null or empy keys
    Object.keys(json).forEach((k) => json[k] == null && delete json[k]);

    var clippy = JSON.stringify( json , null, 4);

    navigator.clipboard.writeText( clippy );
    // good browser support, per https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText

    event.stopPropagation();
    return false;
  }



}