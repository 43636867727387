import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http'
import { ToastrService } from 'ngx-toastr';

// global error handler, for unhandled errors inside of components
// copy pasta from  tutorial https://pusher.com/tutorials/error-handling-angular-part-1/
@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler{
  constructor(private injector: Injector ) { }

  handleError(error: any) {
    const router = this.injector.get(Router);

    if (Error instanceof HttpErrorResponse) {
      console.error("an error occurred here - http response error");
    }
    else {    
      console.error("an error occurred here - other");
    }

    console.log( error );

    // hmm - this doesn't work - injector is null!
    // const toastr = this.injector.get(ToastrService);
    // toastr.error( error )
    
    // tbd...
    // maybe pass error to error control &/or log error to somewhere
    // router.navigate(['error']);
    // prod only? in development this is a hassle

    //, private toastr: ToastrService
  }
}