import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';


const routes: Routes = [

    { path : '', loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule) },
    { path : 'demo', loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule) },
    { path : 'home', loadChildren: () => import('./system/home/home.module').then(m => m.HomeModule) },
    { path : 'auth', loadChildren: () => import('./system/auth/authentication.module').then(m => m.AuthenticationModule) },

    // main route
    { path : 'app', loadChildren: () => import('./system/layout/layout.module').then(m => m.LayoutModule)  },

     // defaut route
    {
        // note : dont use redirect so can preserve url for debugging purposes
        path: '**',
        loadChildren: () =>
            import('./system/module-not-found/module-not-found.module').then(m => m.ModuleNotFoundModule)
    }

    /* 
        {
            path : 'error', 
            component : ErrorComponent
        },
        import { ErrorComponent } from './core/error/error.component';
    */
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}




