import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponseInterceptor } from './http-error-response.interceptor';
import { concatAll, distinctUntilChanged, flatMap, map, mergeAll } from 'rxjs/operators';
import { ErrorResponse, RoutineFailureResponseDto } from './http-error-models';
import { ErrorResponseToastComponent } from './components/error-response-toast.component';
import { RoutineFailureResponseToastComponent } from './components/routine-failure-response-toast.component';
import { ActivatedRoute, Router } from '@angular/router';


@Injectable({ providedIn : 'root' }) // singelton!
export class HttpErrorResponseService {

    private subject: Subject<HttpErrorResponse>;

    public Send(item: HttpErrorResponse) {
        this.subject.next(item);
    }


    blobToText(blob: any): Observable<string> {
        return new Observable<string>((observer: any) => {
            let isBlob = blob instanceof Blob;
            if (!blob || !isBlob ) {
                observer.next('{"server-should-send-blob":"true"}');
                observer.complete();
            } else {
                let reader = new FileReader();
                reader.onload = event => {
                    observer.next((<any>event.target).result);
                    observer.complete();
                };
                reader.readAsText(blob);
            }
        });
    }

    private isTokenExpired(): boolean {  
        if (this.isNullOrEmpty(localStorage.getItem('auth'))) 
            return true;
        else if(localStorage.getItem('auth') === 'INVALID')
            return true;
        else
            return false;
      }
      
    private isNullOrEmpty(str: string | null | undefined): boolean {
        return !str || str.trim() === '';
    }

    constructor(
            private toastr: ToastrService,
            private router : Router,
            private route : ActivatedRoute
        ) {
        this.subject = new Subject<HttpErrorResponse>();

        this.subject.pipe(
                map 
                  ( 
                      (response : HttpErrorResponse) => { 
                        
                        return this.blobToText(response.error).pipe( 

                            map(
                                ( text: string ) =>
                            
                                 {
                                    return <ErrorBully>{
                                        'status' : response.status,
                                        'statusText' : response.statusText, 
                                        'time' : Date.now(),
                                        'blob' : response.error,
                                        'json' : JSON.parse(text)  ,
                                        'jsonText' : text,
                                        'message' : response.message,
                                        'response' : response
                                    }
                                }
                            )
                        );
                    }
                  ),
                  concatAll() ,
                  distinctUntilChanged
                  (
                      ( prev:ErrorBully, curr:ErrorBully ) =>  {
                              return true 
                                 && prev.status === curr.status 
                                 && prev.jsonText === curr.jsonText 
                                 && ( prev.time > curr.time - 2000 )
                                // allow repeat errors after 2 second timediff
                          }
                  )
        )
        .subscribe({

            next : response => this.processError( response ),

            error : error =>{  console.log(error);  }
        });
        
    }


    processError(error: ErrorBully ){
       
        if (error.status == 0){
            let urlBase = error.response.url.split('?')[0]
            let message = `Unable to connect to website : ${urlBase}`
            this.toastr.error( message, "Connection error", { timeOut : 10000 });
        }
        else if ( error.status === 400 ){
                           
            let response = RoutineFailureResponseDto.fromJS( error.json );
            this.showValidationErrorResponse( response );

        }else{
            let title = {
                    401 : "Unauthorized Request",
                    500 : "Server Error",
                }[ error.status ] ?? "Error"

            let response = ErrorResponse.fromJS( error.json );
            if( error.status == 401 && this.isTokenExpired()) {  
                response.message = "Your session has been expired or trying to access an unauthorized request";
                this.showErrorReponse( response, "Session Expired/Unauthorized Request", error.status );
                let url = window.location.href;
                this.router.navigate(['auth','logout-done'], { queryParams : { redirectTo : url }, queryParamsHandling:'merge'});
            }
            else if ( error.status == 401 && 
                 (response.message ?? '' ).indexOf('The token is invalid.') > -1){
                    this.showErrorReponse( response, title, error.status );
                let url = window.location.href;
                this.router.navigate(['auth','logout-done'], { queryParams : { redirectTo : url }, queryParamsHandling:'merge'});
            }
        }

     

    }


    showErrorReponse( problem : ErrorResponse, title : string, code : number){
        let opts = { toastComponent: ErrorResponseToastComponent, toastClass : 'ngx-toastr toast-error', disableTimeOut : true }         
        let toast = this.toastr.show( '', title, opts )

        toast.toastRef.componentInstance.problem = problem; 
        toast.toastRef.componentInstance.code = code;
    }

    showValidationErrorResponse ( problem : RoutineFailureResponseDto ){
        let title = problem.failureType;
        title = title.replace(/([A-Z])/g, " $1"); // regex to sentence case from title case!

        let opts = { toastComponent: RoutineFailureResponseToastComponent, toastClass : 'ngx-toastr toast-error', disableTimeOut : true }
        let toast = this.toastr.show( '', title , opts);
        toast.toastRef.componentInstance.problem = problem;

        console.log( problem );
    }


}

/**
 * "Bullies" all http errors into a unified format
 */
interface ErrorBully{
    status : number
    statusText : string
    time : number
    blob : any
    json : any,
    jsonText : string,
    message : string
    response : HttpErrorResponse
}

