import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


// loading bar
import { LOADING_BAR_CONFIG } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

// credentials
import { ApiCredentialsService } from './credentials/api-credentials.service';
import { ApiAuthenticator } from './credentials/api-authenticator.interceptor';

// error
import { GlobalErrorHandlerService } from './error/global-error-handler.service';
import { HttpErrorResponseInterceptor } from './error/http-error-response.interceptor';

// toaster
import { ToastrModule } from 'ngx-toastr';    
import { HttpErrorResponseService } from './error/http-error-response.service';
import { ErrorResponseToastComponent } from './error/components/error-response-toast.component';
import { RoutineFailureResponseToastComponent } from './error/components/routine-failure-response-toast.component';
import { AppConfig, APP_CONFIG } from '../app.config';
import { CoreComponent } from './core.component';
import { SpinnerComponent } from '@shared/spinner/spinner.component';
import { LoadingInterceptor } from '@shared/spinner/loading.interceptor';

@NgModule({
    declarations: [
        ErrorResponseToastComponent,
        RoutineFailureResponseToastComponent,
        CoreComponent,
        SpinnerComponent
    ],
    imports: [
        // vendor
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 5000, // 15 seconds
            closeButton: true,
            progressBar: true,
        }),
        RouterModule,
        HttpClientModule,
        LoadingBarHttpClientModule,
        //ToastrModule.forRoot()
    ],
    providers: [
        { provide: "API_BASE",
            useFactory: (config: AppConfig) => config.apiUrl,
            deps: [APP_CONFIG] },
        { provide: new InjectionToken<string>('API_BASE'),
            useFactory: (config: AppConfig) => config.apiUrl,
            deps: [APP_CONFIG] },
        { provide: AppConfig,
            useFactory: (config: AppConfig) => config,
            deps: [APP_CONFIG] },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        HttpErrorResponseService,
        // loading bar config
        //{ provide: LOADING_BAR_CONFIG, useValue: { latencyThreshold: 400 } },
        // interceptors
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiAuthenticator, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorResponseInterceptor, multi: true },
    ],
    exports: [
        CoreComponent,
        ToastrModule,
        LoadingBarHttpClientModule,
        SpinnerComponent
    ]
})
export class CoreModule {}
