import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppConfig } from './app.config';
@Component({
  selector: 'app-root',
  template: '<core-component></core-component>'
})
export class AppComponent {
  constructor(
    public element: ElementRef,
    private titleService: Title,
    private configService: AppConfig

    ) {
      this.titleService.setTitle(this.configService.clientHeader + " " + this.configService.envsHeader);
  }

  get envsHeader(): string {
    return this.configService.envsHeader;
  }

  
  get clientHeader(): string {
    return this.configService.clientHeader;
  }

  title = 'web-ui';
}


