/** function enum */
export enum fxn {
  activity =  'fxn-activity',
  activity_template =  'fxn-activity-temp',
  adjuster_activites_log =  'fxn-adjuster-activites-log',
  adjuster_assignment_calendar =  'fxn-adjuster-assignment-calendar',
  audit_configuration_manager = 'fxn-audit-process-manager',
  audit_claim = 'fxn-audit-claim',
  audit_search = 'fxn-audit-search',
  average_reserve =  'fxn-average-reserves',
  reassign_adjuster_team =  'fxn-reassign-adjuster-team',
  teams_and_adjusters =  'fxn-teams-and-adjusters',
  alert_a_claim =  'fxn-alert-a-claim',
  alerts =  'fxn-alerts',
  claim_positive_pay =  'fxn-claim-positive-pay',
  claim_acc_recon =  'fxn-claim-acc-recon',
  claim_bodily_injury =  'fxn-claims-bi',
  claim_bodily_injury_IH =  'claims_bi_ih_ng',
  claim_litigation =  'fxn-claimlit',
  claims_split_and_reassign =  'fxn-claimsplitre',
  claims_split =  'fxn-claimsplit',
  claim_summary =  'fxn-claim-summary',
  claims_triage_view =  'fxn-claimstriageview',
  claim_update =  'fxn-claim-update',
  claim_view =  'fxn-claim-view',
  clear_desk =  'fxn-clear-desk',
  claims_comp_neg =  'fxn-comp-neg',
  claims_copart_aps =  'fxn-copart-aps',
  claims_coverages_desk_view =  'fxn-coverages-desk-view',
  coverage_order_manager =  'fxn-coverage-order-manager',
  bulk_expense_definition = 'fxn-bulk-expense-definition',

  reassign_coverage =  'fxn-reassign-coverage',
  doi_calendar =  'fxn-doi-calendar',
  doi_search =  'fxn-doi-search',
  doi_claims =  'fxn-doi-claims',
  doi_no_claim =  'fxn-doi-no-claim',
  automatic_diary_config =  'fxn-autodiarydef',
  automatic_diary_add =  'fxn-autodiaryset',
  diary =  'fxn-diary',
  documents_by_adjuster =  'fxn-documents-by-adjuster',
  documents_by_claim =  'fxn-documents-by-claim',
  claims_siu =  'fxn-claimsiu',
  fraudmanager =  'fxn-fraudmanager',
  claims_fraud =  'fxn-claimfraud',
  gencodes =  'fxn-gencodes',
  block_feature = 'fxn-block-feature',
  claim_involved_parties =  'fxn-involved-parties',
  claim_party_vendors =  'fxn-party-vendors',
  claim_party_rentals =  'fxn-party-rentals',
  payments_approval = 'fxn-approve-payments',
  pip_tally = 'fxn-pip-tally',
  print_checks = 'fxn-print-checks',
  claim_reserves =  'fxn-reserves',
  salvage_locations =  'fxn-salvage-locations',
  claim_salvage_total_loss =  'fxn-salvage-total-loss',
  claims_subrogation =  'fxn-claims-subro',
  sys_messages =  'fxn-sys_messages',
  time_d_calendar =  'fxn-time-d-calendar',
  time_d_pending =  'fxn-time-d-pending',
  time_d_report =  'fxn-time-d-report',
  time_d_setup =  'fxn-time-d-setup',
  claim_uw_referral =  'fxn-uw-referral',
  claim_view_adjusters =  'fxn-viewadj',
  close_reopen_claim = 'fxn-close-reopen-claim',
  fnoli =  'fxn-fnoli',
  hub =  'fxn-hub',
  claim_desk =  'fxn-claim-desk',
  claim_inquiry =  'fxn-claim-inquiry',
  diaries_to_do =  'fxn-diaries-to-do',
  diaries_by_month =  'fxn-diaries-by-month',
  claim_clone =  'fxn-cloneclaim',
  claim_form_letters =  'fxn-claimformletter',
  catastrophe_update =  'fxn-cat-update',
  view_claim_finance='fxn-view-finance',
  view_claim_finance_details='fxn-view-finance_details',
  transactions = 'fxn-transactions',
  transactions_fast_track = 'fxn-transactions-fast-track',
  vendor_checks_draft_search ="fxn-vendor-drafts-search",
  finance_search_by_payee='fxn-finance-search-by-payee',
  reverse_void_stop_pay='fxn-reverse-void-stop-pay',
  view_claim_finances_by_coverage ='fxn-finsummary',
  view_incurred_loss_transactions_ledger = 'fxn-incurred-loss-summation',
  view_reserves_transactions_ledger = 'fxn-reserves-summation-log',
  coverage_manager='fxn-coverage-manager',
  stop_void_bulk_checks = 'fxn-checks-stop-void-bulk',
  metro_police =  'fxn-metro-police',

  claim_search_home="fxn-claim-search-home",
  // future ones are below
  claims_director='fxn-iso-claim-director',
  call_recording = 'fxn-call-recording',
  outside_vendors = 'fxn-outside-vendors',
  critical_claim_fields_update = 'fxn-claim-critical-fields-update',
  coverage_analysis = 'fxn-coverage-analysis',

  reports = 'fxn-reports',
  checks_reports = 'fxn-check-reports',
  checks_correction_admin = 'fxn-checks-corrections-admin',
  mail_messaging = 'fxn-mail-messaging',
  payments_appoval_admin = 'fxn-payments-approval-admin',
  auto_glass_report = 'fxn-auto-glass-report',
  reassign_claim_admin = 'fxn-reassign-claim-admin',
  critical_claim_fields_update_admin = 'fxn-critical-claim-fields-update-admin',
  bulk_expense_import = 'fxn-bulk-expense-import',
  bulk_expense_import_setup = 'fxn-bulk-expense-import-setup',
  litigation_calendar = 'fxn-litigation-calendar',
  communication_templates = 'fxn-communication-templates',
  report_definitions = 'fxn-reports-details',
  report_parameters ='fxn-reports-parameters',
  nightly_reports = 'fxn-nightly-reports',

  company_holidays = 'fxn-company-holidays',
  security_groups = 'fxn-security-groups',
  vendors = 'fxn-vendors',
  coverages_manager = 'fxn-coverages-manager',
  form_letter_mnemonics_setup = 'fxn-form-letter-mnemonics-setup',
  form_letter_mnemonics_list = 'fxn-form-letter-mnemonics-list',
  form_letter_design_list = 'fxn-form-letter-design-list',
  financial_transaction_types = 'fxn-financial-transaction-types',
  automatic_form_letter_anchors = 'fxn-automatic-form-letter-anchors',
  functions_menu_table = 'fxn-functions-menu-table',
  reserves_appoval_admin = 'fxn-reserves-approval-admin',
  reserves_appoval = 'fxn-reserves-approval',


  // functions used for permissions : these do not have dedicated screens
  adjuster_activity_types = 'fxn-adjuster-activities-control',
  adjuster_licensing = 'fxn-adjuster-licensing',
  adjuster_manual_checks = 'fxn-adjuster-manual-check-config',
  ir_desk = 'fxn-irdesk',
  documents_inbox = 'fxn-infothekdesk',
  
  placeholder = 'xxx'

}

export class AppRouteMaps {

  static get routes() : FunctionInfo[] {
    return Array.from( AppRouteMaps.maps.values() );
  }


  /** 
   * Returns the angular or web forms functionCode for a given function
   * 
   * note: mostly used for links from one component to another
   * future note : modify this method to selectively link to an angular or webforms component,
   *     preferably read from a configuration settings, per component
   */
  static WhatFxnToRender( f : fxn ): FxnCodePlus{
    let map = AppRouteMaps.maps.get(f);

    // try use angular component if it is specifed & todo flas is not set
    let ngFxn = map.ngFxn;
    if ( ngFxn != '' && ( map.toDo !== true )){
      return <FxnCodePlus>{
        fxnCode : map.ngFxn,
        isAngular : true
      };
    }

    return <FxnCodePlus>{
      fxnCode : map.wfFxn,
      isAngular : false
    };
  }

  /** 
   * Returns true when a function code references an angular component 
   */
  static isAngularFunction( fxnCode : string ) : boolean {  
    if (  AppRouteMaps._ngFunctions.size > 0)
      return AppRouteMaps._ngFunctions.has( fxnCode );

    let set = new Set<string>();
    for( var x of AppRouteMaps.maps.values()){
      if (x.ngFxn != '') set.add( x.ngFxn );
    }
    AppRouteMaps._ngFunctions = set;
    return AppRouteMaps._ngFunctions.has( fxnCode );
  }
  static _ngFunctions : Set<string> = new Set<string>();
  


  /**
   * Get a function key for an angular or dotClaim function code
   */  
  private static GetFxnByFxnCode( fxnCode : string ) : fxn {

    if ( AppRouteMaps._fxnCodeToFxnLookup['to-be-initialized'] == null )
      return AppRouteMaps._fxnCodeToFxnLookup[fxnCode];

    // else, compute lookup
    let lookup : { [key:string] : fxn } = {}
    for( let m of AppRouteMaps.maps.keys() ){
        let map = AppRouteMaps.maps.get(m);
        lookup[map.ngFxn] = m;
        lookup[map.wfFxn] = m;
    }  
    let result = lookup[fxnCode];
    AppRouteMaps._fxnCodeToFxnLookup = lookup;
    return result;
  } 
  private static _fxnCodeToFxnLookup : { [key:string] : fxn } = { 'to-be-initialized' : fxn.placeholder };

  /**
   * Gets function info for an angular or web forms function code
   */
  static GetFunctionInfo( fxnCode : string ) : FunctionInfo{
    let fxn = AppRouteMaps.GetFxnByFxnCode( fxnCode );
    return AppRouteMaps.maps.get(fxn);
  }

  /**
   * Get component and module for an angular function code
   * If the function code is not an angular function code, an object with null values is returend
   */
  static async GetComponentAndModule(ngFxnCode :  string): Promise<{ module: any; component: any; fxn : fxn }> {

    var isAngular = AppRouteMaps.isAngularFunction( ngFxnCode );

    let fxn = AppRouteMaps.GetFxnByFxnCode( ngFxnCode );

    if ( !isAngular )
      return { module: null, component: null, fxn : fxn };


    var item = AppRouteMaps.maps.get( fxn );

    return { module : await item.module(), component : await item.component(), fxn : fxn };
  }



  static maps : Map<fxn, FunctionInfo> = new Map([
      [fxn.activity, {
          ngFxn : 'activity-ng',
          wfFxn : 'activity',
          description : 'Activity',
          module : () =>  import('./features/activity/activity.module').then(m => m.ActivityModule),
          component : () => import('./features/activity/main/main.component').then( m => m.MainComponent),
          claimKey : true
      }],

      [fxn.activity_template , {
        ngFxn : 'activity-temp-ng',
        wfFxn : 'ActivityTemp',
        description : 'Activity-template',
        module  : () =>  import('./features/activity/activity.module').then(m => m.ActivityModule),
        component : () => import('./features/activity/template-config/template-config.component').then( m => m.TemplateConfigComponent)
      }],
    
      [fxn.adjuster_activites_log, {
        ngFxn : 'adjuster-activites-log-ng',
        wfFxn : 'UsersActivities',
        description : 'Adjuster Activities Log',
        module  : () =>  import('./features/adjusters/adjusters.module').then(m => m.AdjustersModule),
        component : () => import('./features/adjusters/adjuster-activities-log/adjuster-activities-log.component').then(m => m.AdjusterActivitiesLogComponent)
      }],

      [fxn.adjuster_assignment_calendar , {
        ngFxn : 'adjuster-assignment-calendar',
        wfFxn : 'adjcalendar',
        description : 'Adjuster Assignment Calendar',
        module  : () =>  import('./features/adjusters/adjusters.module').then(m => m.AdjustersModule),
        component : () => import('./features/adjusters/assignment-calendar/assignment-calendar.component').then(m => m.AssignmentCalendarComponent)
      }],
      [fxn.reassign_adjuster_team , {
        ngFxn : 'reassign-adjuster-team-ng',
        wfFxn : 'reassignteam',
        description : 'Reassign Adjuster Team',
        module  : () =>  import('./features/adjusters/adjusters.module').then(m => m.AdjustersModule),
        component : () => import('./features/adjusters/reassign-adjuster-team/reassign-adjuster-team.component').then(m => m.ReassignAdjusterTeamComponent)
      }],
      [fxn.teams_and_adjusters , {
        ngFxn : 'teams-and-adjusters-ng',
        wfFxn : 'teamadj',
        description : 'Teams And Adjusters',
        module  : () =>  import('./features/adjusters/adjusters.module').then(m => m.AdjustersModule),
        component : () => import('./features/adjusters//teams-and-adjusters/teams-and-adjusters.component').then(m => m.TeamsAndAdjustersComponent)
      }],

      [fxn.alert_a_claim , {
        ngFxn : 'alert-a-claim-ng',
        wfFxn : 'unverifyclaim',
        description : 'Alert A Claim',
        module  : () =>  import('./features/alerts/alerts.module').then(m => m.AlertsModule),
        component : () => import('./features/alerts/alert-a-claim/alert-a-claim.component').then( m => m.AlertAClaimComponent)
      }],

      [fxn.audit_claim , {
         ngFxn : 'audit-claim-ng', 
         wfFxn:'auditclaim', 
         description : 'Audit a Claim', 
         module  : () =>  import('./features/audits/audits.module').then(m => m.AuditsModule),
        //  component : () => import('./features/audits/audit-worksheet/worksheet-demo/worksheet-demo.component').then( m => m.WorksheetDemoComponent),
         component : () => import('./features/audits/audit-claim/audit-claim.component').then( m => m.AuditClaimComponent),
         claimKey : true
      }],

      [fxn.audit_configuration_manager , {
         ngFxn : 'audit-process-manager-ng',
         wfFxn:'auditmana',
         description : 'Audit Process Manager',
         module  : () =>  import('./features/audits/audits.module').then(m => m.AuditsModule),
         component : () => import('./features/audits/audit-config/audit-config.component').then( m => m.AuditConfigComponent)
      }], 
      
      [fxn.audit_search , {
        ngFxn : 'audit-search-ng',
        wfFxn:'auditsearch',
        description : 'Search Audits',
        module  : () =>  import('./features/audits/audits.module').then(m => m.AuditsModule),
        component : () => import('./features/audits/audit-search/audit-search.component').then( m => m.AuditSearchComponent)
      }], 


      [fxn.bulk_expense_definition , {
        ngFxn : 'bulk-expense-import-setup-ng',
        wfFxn : 'fileimportsetup',
        description : 'Bulk Expense Definition',
        module  : () =>  import('./features/bulk-expense-definition/bulk-expense-definition.module').then(m => m.BulkExpenseDefinitionModule),
        component : () => import('./features/bulk-expense-definition/main/main.component').then( m => m.MainComponent)
      }],
      [fxn.alerts , {
        ngFxn : 'alerts-ng',
        wfFxn : 'alerts',
        description : 'Claim Alerts',
        module  : () =>  import('./features/alerts/alerts.module').then(m => m.AlertsModule),
        component : () => import('./features/alerts/claim-alerts/alerts.component').then( m => m.AlertsComponent),
        claimKey : true
      }],

      [fxn.claim_positive_pay , {
        ngFxn : 'claim-positive-pay-ng',
        wfFxn : 'claimpositivepay',
        description : 'Claims Account Positive Pay',
        module  : () =>  import('./features/bank-integration/bank-intg.module').then(m => m.BankIntegrationModule),
        component : () => import('./features/bank-integration/positive-pay/positive-pay.component').then( m => m.PositivePayComponent)
      }],
      [fxn.claim_acc_recon , {
        ngFxn : 'claim-acc-recon-ng',
        wfFxn : 'claimaccrecon',
        description : 'Claims Account Reconcilliation',
        module  : () =>  import('./features/bank-integration/bank-intg.module').then(m => m.BankIntegrationModule),
        component : () => import('./features/bank-integration/reconcile-checks/reconcile-checks.component').then( m => m.ReconcileChecksComponent)
      }],

      [fxn.claim_bodily_injury , {
        ngFxn : 'claims-bi-ng',
        wfFxn : 'claimsbi',
        description : 'Bodily-injury',
        module  : () =>  import('./features/body-injury/bi.module').then(m => m.BodilyInjuryModule),
        component : () =>  import('./features/body-injury/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],
      [fxn.claim_bodily_injury_IH , {
        ngFxn : 'claims_bi_ih_ng',
        wfFxn : 'claimsbi-ih',
        description : 'Bodily-injury-ih',
        module  : () =>  import('./features/body-injury-IH/bi.module').then(m => m.BodilyInjuryIHModule),
        component : () =>  import('./features/body-injury-IH/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],

      [fxn.claim_litigation , {
        ngFxn : 'claimlit-ng',
        wfFxn : 'ClaimLit',
        description : 'Litigation',
        module  : () =>  import('./features/claim-litigation/claim-litigation.module').then(m => m.ClaimLitigationModule),
        component : () => import('./features/claim-litigation/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],

      [fxn.claims_split_and_reassign , {
        ngFxn : 'claimsplitre-ng',
        wfFxn : 'claimsplitRe',
        description : 'Reassign',
        module  : () => import('./features/claim-reassign/claim-reassign.module').then(m => m.ClaimReassignModule),
        component : () => import('./features/claim-reassign/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],

      [fxn.claims_split , {
        ngFxn : 'claimsplit-ng',
        wfFxn : 'claimsplit',
        description : 'Split',
        module  : () => import('./features/claim-split/claim-split.module').then(m => m.ClaimSplitModule),
        component : () => import('./features/claim-split/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],

      [fxn.claim_summary , {
        ngFxn : 'claim-summary-ng',
        wfFxn : 'ClaimSum2',
        description : 'Claim Summary',
        module  : () => import('./features/claim-summary/claim-summary.module').then(m => m.ClaimSummaryModule),
        component : () =>  import('./features/claim-summary/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],
   
      [fxn.claims_triage_view , {
        ngFxn : 'claimstriageview-ng',
        wfFxn : 'ClaimsTraigeView',
        description : 'Triage View',
        module  : () => import('./features/claim-triage/claim-triage.module').then(m => m.ClaimTriageModule),
        component : () => import('./features/claim-triage/claim-triage.component').then( m => m.ClaimTriageComponent),
        claimKey : true
      }],

      [fxn.claim_update , {
        ngFxn : 'claim-update-ng',
        wfFxn : 'NewMod',
        description : 'Claim Mod',
        module  : () => import('./features/claim-update/claim-update.module').then(m => m.ClaimUpdateModule),
        component : () =>import('./features/claim-update/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],
      [fxn.claim_view , {
        ngFxn : 'claim-view-ng',
        wfFxn : 'NewView',
        description : 'Claim View',
        module  : () =>  import('./features/claim-update/claim-update.module').then(m => m.ClaimUpdateModule),
        component : () => import('./features/claim-update/view/view.component').then(m => m.ViewComponent),
        claimKey : true
      }],
    

      [fxn.clear_desk , {
        ngFxn : 'clear-desk-ng',
        wfFxn : 'claimfinish',
        description : 'Clear Desk',
        module  : () => import('./features/clear-desk/clear-desk.module').then(m => m.ClearDeskModule),
        component : () => import('./features/clear-desk/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],
    

      [fxn.claims_comp_neg , {
        ngFxn : 'comp-neg-ng',
        wfFxn : 'compneg',
        description : 'Comp Neg',
        module  : () => import('./features/comp-neg/comp-neg.module').then(m => m.CompNegModule),
        component : () => import('./features/comp-neg/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],
    
      [fxn.claims_copart_aps , {
        ngFxn : 'copart-aps-ng',
        wfFxn : 'copartaps',
        description : 'Copart',
        module  :  () => import('./features/copart/copart.module').then(m => m.CopartModule),
        component : () => import('./features/copart/copart-aps/copart-aps.component').then( m => m.CopartApsComponent),
        claimKey : true
      }],
    

      [fxn.claims_coverages_desk_view , {
        ngFxn : 'coverages-desk-view-ng',
        wfFxn : 'NewCov',
        description : 'Coverage',
        module  :  () => import('./features/coverage/coverage.module').then(m => m.CoverageModule),
        component : () => import('./features/coverage/main/coverages-by-claim.component').then(m => m.CoveragesByClaimComponent),
        claimKey : true
      }],
      [fxn.reassign_coverage , {
        ngFxn : 'reassign-coverage-ng',
        wfFxn : 'featureadjre',
        description : 'Reassign Coverages',
        module  :  () => import('./features/coverage/coverage.module').then(m => m.CoverageModule),
        component : () => import('./features/coverage/reassign-coverages/reassign-coverages.component').then(m => m.ReassignCoveragesComponent)
      }],
   

      [fxn.doi_calendar , {
        ngFxn : 'doi-calendar-ng',
        wfFxn : 'doical',
        description : 'Doi Calendar',
        module  : () => import('./features/dept-of-insurance/dept-of-insurance.module').then(m => m.DeptOfInsuranceModule),
        component : () => import('./features/dept-of-insurance/doi-calendar/doi-calendar.component').then(m => m.DoiCalendarComponent),
      }],
      [fxn.doi_search , {
        ngFxn : 'doi-search-ng',
        wfFxn : 'doisearch',
        description : 'Doi Search',
        module  : () => import('./features/dept-of-insurance/dept-of-insurance.module').then(m => m.DeptOfInsuranceModule),
        component : () => import('./features/dept-of-insurance/doi-search/doi-search.component').then(m => m.DoiSearchComponent),
      }],
      [fxn.doi_claims , {
        ngFxn : 'doi-claims-ng',
        wfFxn : 'claimsdoi',
        description : 'Doi Main - With Claim',
        module  : () => import('./features/dept-of-insurance/dept-of-insurance.module').then(m => m.DeptOfInsuranceModule),
        component : () => import('./features/dept-of-insurance/doi-main/doi-main.component').then(m => m.DoiMainComponent),
        claimKey : true
      }],
      [fxn.doi_no_claim , {
        ngFxn : 'doi-no-claim-ng',
        wfFxn : 'doimang',
        description : 'Doi Main - Without Claim',
        module  : () => import('./features/dept-of-insurance/dept-of-insurance.module').then(m => m.DeptOfInsuranceModule),
        component : () => import('./features/dept-of-insurance/doi-main/doi-main.component').then(m => m.DoiMainComponent),
      }],


      [fxn.automatic_diary_config , {
        ngFxn : 'autodiarydef-ng',
        wfFxn : 'autodiarydef',
        description : 'Configure Automatic Diary',
        module  : () => import('./features/diary/diary.module').then(m => m.DiaryModule),
        component : () => import('./features/diary/diary-auto-config/dac-main.component').then( m => m.DiaryAutoConfigComponent),
      }],
      [fxn.automatic_diary_add , {
        ngFxn : 'autodiaryset-ng',
        wfFxn : 'autodiaryset',
        description : 'Add Automatic Diary',
        module  : () => import('./features/diary/diary.module').then(m => m.DiaryModule),
        component : () => import('./features/diary/diary-auto-assign/daa-screen/daa-screen.component').then( m => m.DiaryAutoAssignScreenComponent),
        claimKey : true
      }],
      [fxn.diary , {
        ngFxn : 'diary-ng',
        wfFxn : 'diary',
        description : 'Diary',
        module  : () => import('./features/diary/diary.module').then(m => m.DiaryModule),
        component : () => import('./features/diary/diary/diary.component').then( m => m.DiaryComponent),
        claimKey: true
      }],


      [fxn.documents_by_adjuster , {
        ngFxn : 'documents-by-adjuster-ng',
        wfFxn : 'InfoThekDesk',
        description : 'Documents by Adjuster, Laserfiche',
        module  : () => import('./features/documents/laserfiche/documents.module').then(m => m.DocumentsModule),
        component : () => import('./features/documents/laserfiche/adjuster-documents/adjuster-documents.component').then(m => m.AdjusterDocumentsComponent)
      }],
      [fxn.documents_by_claim , {
        ngFxn : 'documents-by-claim-ng',
        wfFxn : 'claimdoc',
        description : 'Documents by Claim',
        module  : () => import('./features/documents/laserfiche/documents.module').then(m => m.DocumentsModule),
        component : () => import('./features/documents/laserfiche/claim-documents/claim-documents.component').then(m => m.ClaimDocumentsComponent),
        claimKey : true
      }],


      [fxn.claims_siu , {
        ngFxn : 'claimsiu-ng',
        wfFxn : 'claimsiu',
        description : 'SIU',
        module  : () => import('./features/fraud-detection/fraud-detection.module').then(m => m.FraudDetectionModule),
        component : () => import('./features/fraud-detection/main/claim-siu/claim-siu.component').then(m => m.ClaimSiuComponent),
        claimKey : true
      }],
      [fxn.fraudmanager , {
        ngFxn : 'fraudmanager-ng',
        wfFxn : 'fraudmanager',
        description : 'Triage/Fraud Questions',
        module  : () => import('./features/fraud-detection/fraud-detection.module').then(m => m.FraudDetectionModule),
        component : () => import('./features/fraud-detection/main/fraud-questions/fraud-questions.component').then(m => m.FraudQuestionsComponent)
      }],
      [fxn.claims_fraud , {
        ngFxn : 'claimfraud-ng',
        wfFxn : 'claimfraud',
        description : 'SIU Potential',
        module  : () => import('./features/fraud-detection/fraud-detection.module').then(m => m.FraudDetectionModule),
        component : () => import('./features/fraud-detection/main/claim-siu-potential/claim-siu-potential.component').then(m => m.ClaimSiuPotentialComponent),
        claimKey : true
      }],

      [fxn.gencodes , {
        ngFxn : 'gencodes-ng',
        wfFxn : 'gencodes',
        description : 'General Codes',
        module  : () =>  import('./features/general-codes/gc.module').then(m => m.GeneralCodesModule),
        component : () => import('./features/general-codes/main/main.component').then( m => m.MainComponent)
      }],

      [fxn.coverage_order_manager , {
        ngFxn : 'coverage-order-manager-ng',
        wfFxn : 'coverage-order-manager', // should this read EBRCOMan?
        description : 'Coverage Order Manager',
        module  : () =>  import('./features/coverage-order-manager/coverage-order-manager.module').then(m => m.CoverageOrderManagerModule),
        component : () => import('./features/coverage-order-manager/main/main.component').then( m => m.MainComponent)
      }],

    

      [fxn.claim_involved_parties , {
        ngFxn : 'involved-parties-ng',
        wfFxn : 'invpnew',
        description : 'Involved Party',
        module  : () => import('./features/involved-party/involved-party.module').then(m => m.InvolvedPartyModule),
        component : () => import('./features/involved-party/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],
      [fxn.claim_party_vendors , {
        ngFxn : 'party-vendors-ng',
        wfFxn : 'invpartyvendors',
        description : 'Involved Party Vendor List',
        module  : () => import('./features/involved-party/involved-party.module').then(m => m.InvolvedPartyModule),
        component : () => import('./features/involved-party/party-vendors/party-vendors.component').then( m => m.PartyVendorsComponent),
        claimKey : true
      }],


      [fxn.claim_party_rentals , {
        ngFxn : 'party-rentals-ng',
        wfFxn : 'invpartyrentals',
        description : 'Rental',
        module  : () => import('./features/rentals/rentals.module').then(m => m.RentalsModule),
        component : () => import('./features/rentals/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],

      [fxn.claim_reserves , {
        ngFxn : 'reserves-ng',
        wfFxn : 'resinspect',
        description : 'Reserves',
        module  :  () => import('./features/reserves/reserves.module').then(m => m.ReservesModule),
        component : () => import('./features/reserves/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],

      [fxn.salvage_locations , {
        ngFxn : 'salvage-locations-ng',
        wfFxn : 'salvagecomp',
        description : 'Salvage Locations',
        module  :  () => import('./features/salvage/salvage.module').then(m => m.SalvageModule),
        component : () => import('./features/salvage/locations/locations.component').then( m => m.LocationsComponent)
      }],
      [fxn.claim_salvage_total_loss , {
        ngFxn : 'salvage-total-loss-ng',
        wfFxn : 'totallosssalvage',
        description : 'Salvage Total Loss',
        module  :  () => import('./features/salvage/salvage.module').then(m => m.SalvageModule),
        component : () => import('./features/salvage/total-loss-salvage/total-loss-salvage.component').then( m => m.TotalLossSalvageComponent),
        claimKey : true
      }],

      [fxn.claims_subrogation , {
        ngFxn : 'claims-subro-ng',
        wfFxn : 'claimsubro',
        description : 'Subrogation',
        module  : () => import('./features/subrogation/subrogation.module').then(m => m.SubrogationModule),
        component : () => import('./features/subrogation/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],

      [fxn.sys_messages , {
        ngFxn : 'sys_messages-ng',
        wfFxn : 'sys_messages',
        description : 'System Messages',
        module  :  () => import('./features/system-messages/sm.module').then(m => m.SystemMessagesModule),
        component : () => import('./features/system-messages/main/main.component').then( m => m.MainComponent)
      }],

      [fxn.time_d_calendar , {
        ngFxn : 'time-d-calendar-ng',
        wfFxn : 'timedcal',
        description : 'Time Demand Calendar',
        module  :  () => import('./features/time-demand/time-demand.module').then(m => m.TimeDemandModule),
        component : () => import('./features/time-demand/time-demand-calendar/time-demand-calendar.component').then( m => m.TimeDemandCalendarComponent)
      }],
      [fxn.time_d_pending , {
        ngFxn : 'time-d-pending-ng',
        wfFxn : 'timedpending',
        description : 'Time Demand Document Review Queue',
        module  :  () => import('./features/time-demand/time-demand.module').then(m => m.TimeDemandModule),
        component : () => import('./features/time-demand/document-review-queue/document-review-queue.component').then( m => m.DocumentReviewQueueComponent)
      }],
      [fxn.time_d_report , {
        ngFxn : 'time-d-report-ng',
        wfFxn : 'timedreport',
        description : 'Time Demand Reports',
        module  :  () => import('./features/time-demand/time-demand.module').then(m => m.TimeDemandModule),
        component : () => import('./features/time-demand/time-demand-reports/time-demand-reports.component').then( m => m.TimeDemandReportsComponent)
      }],
      [fxn.time_d_setup , {
        ngFxn : 'time-d-setup-ng',
        wfFxn : 'timedsetup',
        description : 'Time Demand Setup',
        module  :  () => import('./features/time-demand/time-demand.module').then(m => m.TimeDemandModule),
        component : () => import('./features/time-demand/time-demand-setup/time-demand-setup.component').then( m => m.TimeDemandSetupComponent)
      }],

      [fxn.claim_uw_referral , {
        ngFxn : 'uw-referral-ng',
        wfFxn : 'underwriting',
        description : 'Underwriting Referral',
        module  : () => import('./features/uw-referral/uw-referral.module').then(m => m.UwReferralModule),
        component : () => import('./features/uw-referral/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],

      [fxn.claim_view_adjusters , {
        ngFxn : 'viewadj-ng',
        wfFxn : 'ViewAdj',
        description : 'View Adjusters',
        module  : () =>  import('./features/view-adjusters/view-adjusters.module').then(m => m.ViewAdjustersModule),
        component : () => import('./features/view-adjusters/view-adjusters.component').then( m => m.ViewAdjustersComponent),
        claimKey : true
      }],

      
      [fxn.fnoli , {
        ngFxn : 'fnoli-ng',
        wfFxn : 'fnoli',
        description : 'First Notice Of Loss',
        module  : () =>  import('./features/fnoli/fnoli.module').then(m => m.FnoliModule),
        component : () => import('./features/fnoli/main/main.component').then( m => m.MainComponent),
      }],

      [fxn.hub , {
        ngFxn : 'hub-ng',
        wfFxn : 'hub',
        description : 'Claim Hub',
        module  :  () => import('./features/claim-hub/claim-hub.module').then(m => m.ClaimHubModule),
        component : () => import('./features/claim-hub/hub-main/hub-main.component').then(m => m.HubMainComponent),
        claimKey : true
      }],

      [fxn.company_holidays , { 
        ngFxn : 'company-holidays-ng', 
        wfFxn:'Holiday', 
        description : 'Company Holidays',
        module: () =>import('./features/holiday/holiday.module').then(m => m.HolidayModule),
        component: () =>import('./features/holiday/company-holiday/holiday.component').then(m => m.HolidayComponent),
      }], 


      [fxn.claim_desk , {
        ngFxn : 'claim-desk-ng',
        wfFxn : 'claimdesk',
        description : 'Claim Desk Home Screen',
        module  :  () => import('./features/home-pages/home-pages.module').then(m => m.HomePagesModule),
        component : () => import('./features/home-pages/claim-desk/claim-desk.component').then(m => m.ClaimDeskComponent),
        calendar : true,
        messages : true,
      }],
      [fxn.claim_inquiry , {
        ngFxn : 'claim-inquiry-ng',
        wfFxn : 'clmninquiry',
        description : 'Claim Inquiry Home Screen',
        module  :  () => import('./features/home-pages/home-pages.module').then(m => m.HomePagesModule),
        component : () => import('./features/home-pages/claim-inquiry/claim-inquiry.component').then(m => m.ClaimInquiryComponent),
        calendar : true,
        messages : true,
      }],
      [fxn.diaries_to_do , {
        ngFxn : 'diaries-to-do-ng',
        wfFxn : 'todo',
        description : 'Diaries To Do Home Screen',
        module  :  () => import('./features/home-pages/home-pages.module').then(m => m.HomePagesModule),
        component : () => import('./features/home-pages/diary-to-do/diaries-to-do.component').then(m => m.DiariesToDoComponent),
        calendar : true,
        messages : true
      }],
      [fxn.diaries_by_month , {
        ngFxn : 'diaries-by-month-ng',
        wfFxn : 'diarymnt',
        description : 'Diaries By Month Home Screen',
        module  :  () => import('./features/home-pages/home-pages.module').then(m => m.HomePagesModule),
        component : () => import('./features/home-pages/diary-by-month/diaries-by-month.component').then(m => m.DiariesByMonthComponent),
      }],
      

      
      [fxn.claim_clone , {
        ngFxn : 'cloneclaim-ng',
        wfFxn : 'CloneClaim',
        description : 'Clone Claim',
        module  : () =>  import('./features/clone-claim/clone-claim.module').then(m => m.CloneClaimModule),
        component : () => import('./features/clone-claim/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],
      
      [fxn.claim_form_letters , {
        ngFxn : 'claimformletter-ng',
        wfFxn : 'claimformletter',
        description : 'Claim Form Letter',
        module  : () =>  import('./features/claim-form-letter/claim-form-letter.module').then(m => m.ClaimFormLetterModule),
        component : () => import('./features/claim-form-letter/main/main.component').then(m => m.MainComponent),
        claimKey : true
      }],

      [fxn.catastrophe_update , {
        ngFxn : 'catupdate-ng',
        wfFxn : 'CatUpdate',
        description : 'Catatstrophe Update',
        module  : () =>  import('./features/catastrophe-update/catastrophe-update.module').then(m => m.CatastropheUpdateModule),
        component : () => import('./features/catastrophe-update/main/main.component').then( m => m.MainComponent),
      }],
      [fxn.average_reserve , {
        ngFxn : 'average-reserves-ng',
        wfFxn : 'avgreserves',
        description : 'Average Reserve',
        module  : () =>  import('./features/average-reserve/average-reserve.module').then(m => m.AverageReserveModule),
        component : () => import('./features/average-reserve/main/main.component').then( m => m.MainComponent),
      }],
      [fxn.view_claim_finance , {
        ngFxn : 'financial-claim-view-ng',
        wfFxn : 'claimview_fin',
        description : 'View Finance',
        module  : () =>  import('./features/view-finance/view-finance.module').then(m => m.ViewFinanceModule),
        component : () => import('./features/view-finance/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],
      [fxn.view_claim_finance_details , {
        ngFxn : 'financial-detail-view-ng',
        wfFxn : 'ftran_view',
        description : 'View Finance Details',
        module  : () =>  import('./features/view-finance/view-finance.module').then(m => m.ViewFinanceModule),
        component : () => import('./features/view-finance/view-financial-detail/view-financial-details.component').then( m => m.ViewFinancialDetails),
        claimKey : true
      }],

      [fxn.transactions , {
        ngFxn : 'payments-ng',
        wfFxn : 'ftrn',
        description : 'Financial Transactions',
        module  : () =>  import('./features/transactions/transactions.module').then(m => m.TransactionsModule),
        component : () => import('./features/transactions/main/transactions.component').then( m => m.TransactionsComponent),
        claimKey : true
      }],
      [fxn.block_feature, {
        ngFxn: 'block-feature-ng',
        wfFxn: 'blockfeature',
        description: 'Block Feature',
        module: () => import('./features/block-features/block-feature.module').then(m => m.BlockFeatureModule),
        component: () => import('./features/block-features/main/main.component').then(m => m.MainComponent),
        claimKey: true
      }],

      [fxn.transactions_fast_track , {
        ngFxn : 'payments-fast-track-ng', 
        wfFxn : 'fastftrn',
        description : 'Financial Transactions Fast Track',
        module  : () =>  import('./features/transactions/transactions.module').then(m => m.TransactionsModule),
        component : () => import('./features/transactions/main/transactions.component').then( m => m.TransactionsComponent),
        claimKey : true
      }],

      [fxn.vendor_checks_draft_search , { 
        ngFxn : 'vendors-checks-draft-search-ng', 
        wfFxn : 'vdsearch',
        description : 'Vendor Draft Search', 
        module  : () =>  import('./features/vendor-draft-search/vendor-draft-search.module').then(m => m.VendorDraftSearchModule),
        component : () => import('./features/vendor-draft-search/vendor-draft-search.component').then( m => m.VendorDraftSearchComponent)
      }], 

      [fxn.payments_appoval_admin , { 
        ngFxn : 'payments-approval-admin-ng', 
        wfFxn:'approveclaims',
        description : 'Approve / All claims', 
        module  : () =>  import('./features/payment-approvals/payment-approvals.module').then(m => m.PaymentApprovalsModule),
        component : () =>  import('./features/payment-approvals/admin-approvals/admin-approvals.component').then(m => m.AdminApprovalsComponent),
      }], 
      
      [fxn.payments_approval , { 
        ngFxn : 'payments-approval-ng', 
        wfFxn:'approvedesk', 
        description : 'Payments Approval',
        module  : () =>  import('./features/payment-approvals/payment-approvals.module').then(m => m.PaymentApprovalsModule),
        component : () =>  import('./features/payment-approvals/main-approvals/main-approvals.component').then(m => m.MainApprovalsComponent),
        claimKey : true
      }], 

      [fxn.finance_search_by_payee , {
        ngFxn : 'payee-search-ng', 
        wfFxn : 'payeeinq',
        description : 'Finance Search By Payee',
        module  : () =>  import('./features/finance-search-by-payee/finance-search-by-payee.module').then(m => m.FinanceSearchByPayeeModule),
        component : () => import('./features/finance-search-by-payee/main/main.component').then( m => m.MainComponent)
      }],

      [fxn.view_claim_finances_by_coverage , {
        ngFxn : 'financials-by-coverage-summary-ng', 
        wfFxn : 'finsummary',
        description : 'View Financial Summary',
        module  : () =>  import('./features/view-financial-summary/view-financial-summary.module').then(m => m.ViewFinancialSummaryModule),
        component : () => import('./features/view-financial-summary/main-summary/main-summary.component').then( m => m.MainSummaryComponent),
        claimKey : true
      }],

      [fxn.view_incurred_loss_transactions_ledger , {
        ngFxn : 'incurred-loss-summation-ng',
        wfFxn : 'inclossdev',
        description : 'Incurred Loss Transactions Ledger',
        module  : () =>  import('./features/view-financial-summary/view-financial-summary.module').then(m => m.ViewFinancialSummaryModule),
        component : () => import('./features/view-financial-summary/main-incurred-loss-ledger/main-incurred-loss-ledger.component').then( m => m.MainIncurredLossLedgerComponent),
        claimKey : true
      }],

      [fxn.view_reserves_transactions_ledger ,
         { 
          ngFxn : 'reserves-summation-ng', 
          wfFxn: 'resdev', 
          description : 'Reserves Transactions Ledger',
          module  : () =>  import('./features/view-financial-summary/view-financial-summary.module').then(m => m.ViewFinancialSummaryModule),
          component : () => import('./features/view-financial-summary/main-reserves-ledger/main-reserves-ledger.component').then( m => m.MainReservesLedgerComponent),
          claimKey : true
      }],  

    
      [fxn.reports, {
        ngFxn: 'reports-ng', 
        wfFxn: 'subreport', 
        description: 'Reports', 
        module  : () =>  import('./features/reports/reports.module').then(m => m.ReportsModule),
        component : () => import('./features/reports/reports/reports.component').then( m => m.ReportsComponent),
      }],

      [fxn.report_definitions, {
        ngFxn: 'reports-details-ng', 
        wfFxn: 'reportmod', 
        description: 'Reports Definitions', 
        module  : () =>  import('./features/reports/reports.module').then(m => m.ReportsModule),
        component : () => import('./features/reports/reports-definition/reports-definition.component').then( m => m.ReportsDefinitionComponent),
      }],

      [fxn.report_parameters, {
        ngFxn: 'reports-parameters-ng', 
        wfFxn: 'ReportModParm', 
        description: 'Report Parameter Definitions', 
        module  : () =>  import('./features/reports/reports.module').then(m => m.ReportsModule),
        component : () => import('./features/reports/reports-parameter-definition/reports-parameter-definition.component').then( m => m.ReportsParameterDefinitionComponent),
      }],

      [fxn.reverse_void_stop_pay , {
        ngFxn : 'check-reverse-stop-void-ng',
        wfFxn : 'fchk',
        description : 'Reverse, Void, Stop Pay',
        module  : () =>  import('./features/reverse-void-stop-pay/reverse-void-stop-pay.module').then(m => m.ReverseVoidStopPayModule),
        component : () => import('./features/reverse-void-stop-pay/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],

      [fxn.pip_tally , {
         ngFxn : 'pip-tally-ng', 
         wfFxn:'piptally',
         description : 'Pip Tally', 
         module  : ()  =>  import('./features/personal-injury/personal-injury.module').then(m => m.PersonalInjuryModule),
         component : () => import('./features/personal-injury/pip-tally/pip-tally.component').then( m => m.PipTallyComponent),
         claimKey : true
       }], 


      [fxn.stop_void_bulk_checks , {  
        ngFxn : 'checks-stop-void-bulk-ng', 
        wfFxn:'svbcheck', 
        description : 'Stop / Void bulk Checks', 
        module : () => import('./features/stop-void-bulk-check/stop-void-bulk-check.module').then(m => m.StopVoidBulkCheckModule), 
        component : () => import('./features/stop-void-bulk-check/main/main.component').then( m => m.MainComponent),
      }], 

      [fxn.print_checks , {
        ngFxn : 'print-checks-ng', 
        wfFxn : 'checks',
        description : 'Print Checks',
        module  : () =>  import('./features/print-checks/print-checks.module').then(m => m.PrintChecksModule),
        component : () => import('./features/print-checks/main/main.component').then( m => m.MainComponent),
      }],
      [fxn.metro_police , {
        ngFxn : 'cpftp-ng',
        wfFxn : 'cpftp',
        description : 'Order Police Report',
        module  : () =>  import('./features/metro-police/metro-police.module').then(m => m.MetroPoliceModule),
        component : () => import('./features/metro-police/main/metro-police/metro-police.component').then(m => m.MetroPoliceComponent),
        claimKey : true
      }],

      [fxn.critical_claim_fields_update_admin, {
        ngFxn: 'crit-claim-fields-admin-upd-ng',
        wfFxn: 'managerclaimmod', 
        description: 'Critical fields Update, Any Claim',
        module  : () =>  import('./features/critical-fields-update/critical-field-update.module').then(m => m.CriticalFieldUpdateModule),
        component : () => import('./features/critical-fields-update/manager-update/manager-update.component').then( m => m.ManagerUpdateComponent),
      }], 

      [fxn.critical_claim_fields_update , { 
        ngFxn : 'crit-claim-fields-upd-ng', 
        wfFxn:'ClaimCriticalUpd', 
        description : 'Critical fields Update, for Claim', 
        module  : () =>  import('./features/critical-fields-update/critical-field-update.module').then(m => m.CriticalFieldUpdateModule),
        component : () => import('./features/critical-fields-update/main-update/main-update.component').then( m => m.MainUpdateComponent),
        claimKey : true
      }], 

      [fxn.bulk_expense_import_setup , { 
        toDo : true, ngFxn : 'bulk-expense-import-setup-ng',
        wfFxn:'fileimportsetup', 
        description : 'Bulk Expenses, File Import config', 
        module : () => import('./features/bulk-expense-definition/bulk-expense-definition.module').then(m => m.BulkExpenseDefinitionModule),
        component : () => import('./features/bulk-expense-definition/main/main.component').then( m => m.MainComponent),
      }], 
      
      [fxn.coverage_manager , {
        ngFxn : 'coverages-manager-ng',
        wfFxn : 'covmanager',
        description : 'Coverage Manager',
        module  : () =>  import('./features/coverage-manager/coverage-manager.module').then(m => m.CoverageManagerModule),
        component : () => import('./features/coverage-manager/main/main.component').then( m => m.MainComponent),
        claimKey : true
      }],

            
      [fxn.close_reopen_claim , {
        ngFxn : 'close-reopen-claim-ng',
        wfFxn : 'cls_reop_claim',
        description : 'Close or Reopen Claim',
        module  : () =>  import('./features/close-reopen-claim/close-reopen.module').then(m => m.CloseReopenModule),
        component : () => import('./features/close-reopen-claim/main/close-reopen-claim').then(m=>m.CloseReopenComponent),
        claimKey : true
      }],

            
      [fxn.claims_director , {
        ngFxn : 'iso-claim-director-ng',
        wfFxn : 'claimdirector',
        description : 'Claims Director',
        module  : () =>  import('./features/claims-director/claims.director.module').then(m => m.ClaimsDirectorModule),
        component : () => import('./features/claims-director/main/main.component').then(m=>m.MainComponent),
        claimKey : true
      }],
      [fxn.financial_transaction_types , {
        ngFxn : 'financial-transaction-types-ng',
        wfFxn : 'fintr',
        description : 'Financial Transaction Types',
        module  : () =>  import('./features/financial-transaction-types/financial-transaction-types.module').then(m => m.FinancialTransactionTypesModule),
        component : () => import('./features/financial-transaction-types/main/main.component').then(m=>m.MainComponent),
      }],

      [fxn.security_groups , { 
        ngFxn : 'security-groups-ng', 
        wfFxn:'secgroup', 
        description : 'Security Groups', 
        module : () =>  import('./features/security-groups/security-groups.module').then(m => m.SecurityGroupsModule), 
        component : () => import('./features/security-groups/main/main.component').then(m=>m.MainComponent), 
      }], 

      
      [fxn.claim_search_home , {
        ngFxn : 'isohome-ng',
        wfFxn : 'isohome',
        description : 'Claim Search Home',
        module  : () =>  import('./features/claim-search-home/claim-search-home.module').then(m => m.ClaimSearchHomeModule),
        component : () => import('./features/claim-search-home/main/main.component').then(m=>m.MainComponent)
      }],

      
      [fxn.vendors , { 
        ngFxn : 'vendors-ng', 
        wfFxn:'prvd', 
        description : 'Vendors', 
        module  : () =>  import('./features/vendor-provider/vendor-provider.module').then(m => m.VendorProvidersModule),
        component : () => import('./features/vendor-provider/vendor-provider/vendor-provider.component').then(m=>m.VendorProviderComponent),
        claimKey : true
       }], 

       [fxn.reassign_claim_admin , {
        ngFxn : 'reassign-claim-admin-ng',
        wfFxn : 'claimreassign',
        description : 'Reassign Claim Admin',
        module  : () =>  import('./features/reassign-claim-admin/reassign-claim-admin.module').then(m => m.ReassignClaimAdminModule),
        component : () => import('./features/reassign-claim-admin/main/main.component').then(m=>m.MainComponent)
      }],
       [fxn.communication_templates , { 
         ngFxn : 'communication-templates-ng',
         wfFxn:'Comms', 
         description : 'Communication Manager', 
         module : () => import('./features/communication-templates/communication-template.module').then(m => m.CommunicationTemplatesModule), 
         component : () => import('./features/communication-templates/main/main.component').then( m => m.MainComponent) }], 

         [fxn.functions_menu_table , {
          ngFxn : 'function-menu-table-ng',
          wfFxn : 'funcsec',
          description : 'Function Menu Table',
          module  : () =>  import('./features/function-table/function-table.module').then(m => m.FunctionTableModule),
          component : () => import('./features/function-table/main/main.component').then(m=>m.MainComponent)
        }],

        [fxn.automatic_form_letter_anchors , {
          ngFxn : 'automatic-form-letter-anchors-',
          wfFxn : 'AFL',
          description : 'AFL-Anchor Category',
          module  : () =>  import('./features/anchor-category/anchor-category.module').then(m => m.AnchorCategoryModule),
          component : () => import('./features/anchor-category/main/main.component').then(m=>m.MainComponent)
        }],

        [fxn.checks_reports,
          {
            ngFxn: 'check-reports-ng',
            wfFxn: 'checksreports',
            description: 'Check Reports',
            module: () => import('./features/checks-reports/checks-reports.module').then(m => m.ChecksreportsModule),
            component: () => import('./features/checks-reports/checks-reports/checks-reports.component').then(m => m.ChecksReportsComponent)
          }
          ],

          [fxn.form_letter_mnemonics_list ,
             { 
              ngFxn : 'form-letter-mnemonics-list-ng', 
              wfFxn:'tflm', 
              description : 'Mnemonics List', 
              module: () => import('./features/mnemonics-list/mnemonics-list.module').then(m => m.MnemonicsListModule),
              component: () => import('./features/mnemonics-list//main/main.component').then(m => m.MainComponent)
             }
          ],

      [fxn.nightly_reports , { 
        ngFxn : 'nightly-reports-ng',
        wfFxn:'NightlyReports', 
        description : 'Nightly Reports', 
        module : () => import('./features/nightly-report/nightly-report.module').then(m => m.NightlyReportModule), 
        component : () => import('./features/nightly-report/main/main.component').then(m=>m.MainComponent), 
      }], 


        [fxn.call_recording , {
          ngFxn : 'call-recording-ng',
          wfFxn : 'CallRec',
          description : 'Call Recording',
          module  : () =>  import('./features/call-recording/call-recording.module').then(m => m.CallRecordingModule),
          component : () => import('./features/call-recording/main/main.component').then(m=>m.MainComponent),
          claimKey : true
        }],
        [fxn.checks_correction_admin , 
          { 
            ngFxn : 'checks-correction-admin-ng', 
            wfFxn:'checksadmin', 
            description : 'Claims Checks Correction', 
            module  : () =>  import('./features/checks-correction-admin/checks-correction-admin.module').then(m => m.ChecksCorrectionAdminModule),
            component : () => import('./features/checks-correction-admin/checks-correction-admin/checks-correction-admin.component').then(m=>m.ChecksCorrectionAdminComponent)
          }], 
       
       [fxn.outside_vendors , { 
        ngFxn : 'outside-vendors-ng',
        wfFxn:'outsidevendors', 
        description : 'Outside Vendors', 
        module  : () =>  import('./features/outside-vendors/outside-vendors.module').then(m => m.OutsideVendorsModule),
        component : () => import('./features/outside-vendors/outside-vendors/outside-vendors.component').then(m=>m.OutsideVendorsComponent),
        claimKey : true
      }],

      [fxn.auto_glass_report , {
        ngFxn : 'auto-glass-report-ng',
        wfFxn : 'autoglassreport',
        description : 'Auto Glass Report',
        module  : () =>  import('./features/auto-glass-report/auto-glass-report.module').then(m => m.AutoGlassReportModule),
        component : () => import('./features/auto-glass-report/main/main.component').then(m=>m.MainComponent)
      }],


        [fxn.mail_messaging , { 
          ngFxn : 'mail-messaging-ng', 
          wfFxn:'mailmess', 
          description : 'Mail Messaging', 
          module : () => import('./features/mail-messaging/mail-messaging.module').then(m => m.MailMessagingModule),
          component : () => import('./features/mail-messaging/main/main.component').then(m=>m.MainComponent)
        }], 

      [fxn.form_letter_design_list , 
        { ngFxn : 'form-letter-design-ng', 
          wfFxn:'UDFormsLetter', 
          description : 'Design Letters', 
          module  : () =>  import('./features/design-letters/design-letters.module').then(m => m.DesignLettersModule),
          component : () => import('./features/design-letters/design-letters/design-letters.component').then(m=>m.DesignLettersComponent),
        }], 
         [fxn.form_letter_mnemonics_setup , {
           ngFxn : 'form-letter-mnemonics-setup-ng', 
           wfFxn:'flsetupmnem', 
           description : 'Mnemonics Setup', 
           module : () => import ('./features/mnemonics-setup/mnemonics-setup.module').then(m => m.MnemonicsSetupModule), 
           component : () => import('./features/mnemonics-setup/main/main.component').then( m => m.MainComponent) }], 

        [fxn.litigation_calendar , { 
          ngFxn : 'litigation-calendar-ng', 
          wfFxn:'LitCal', 
          description : 'Litigation Calendar', 
          module : () => import('./features/litigation-calendar/litigation-calendar.module').then(m => m.LitigationCalendarModule),
          component : () => import('./features/litigation-calendar/main/main.component').then(m=>m.MainComponent)
        }], 

        [fxn.bulk_expense_import , { 
          ngFxn : 'bulk-expense-import-ng', 
          wfFxn:'fileimport', 
          description : 'Bulk Expenses', 
          module : () => import('./features/bulk-expenses/bulk-expenses.module').then(m => m.BulkExpensesModule),
          component : () => import('./features/bulk-expenses/main/main.component').then(m=>m.MainComponent)
        }], 

        [fxn.reserves_appoval_admin , { 
          ngFxn : 'reserves-approval-admin-ng', 
          wfFxn:'approveclaims',
          description : 'Approve Reserves / All claims', 
          module  : () =>  import('./features/reserves-approvals/reserves-approvals.module').then(m => m.ReservesApprovalsModule),
          component : () =>  import('./features/reserves-approvals/admin-approvals/admin-approvals.component').then(m => m.AdminApprovalsComponent),
        }], 
        
        [fxn.reserves_appoval , { 
          ngFxn : 'reserves-approval-ng', 
          wfFxn:'approvedesk', 
          description : 'Reserves Approval',
          module  : () =>  import('./features/reserves-approvals/reserves-approvals.module').then(m => m.ReservesApprovalsModule),
          component : () =>  import('./features/reserves-approvals/main-approvals/main-approvals.component').then(m => m.MainApprovalsComponent),
          claimKey : true
        }],
      /*
        Below are functions that have not yet been implemented in angular 
          The web forms function property (wfFxn) can be used to open the webform component in iframes
      */
     [fxn.coverage_analysis , { toDo : true, ngFxn : 'coverage-analysis-ng', wfFxn:'coverageanalysis', description : '', module : () => null, component : () => null }],
      [fxn.ir_desk , { toDo : true,  ngFxn : '',  wfFxn : 'IRDesk', description : 'Imagewrite Desk', module  : () =>  null, component : () => null, }],      


      // table maintenece, to do 
      //[fxn.finanical_transaction_types , { toDo : true, ngFxn : 'financial-transaction-types-ng', wfFxn:'fintr', description : 'Financial Transaction Types', module : () => null, component : () => null }],     

      // note : this might be redundant with fxn.documents_by_adjuster
      [fxn.documents_inbox , { toDo : true,  ngFxn : '',  wfFxn : 'infothekdesk', description : 'View / Modify Received Documents', module  : () =>  null, component : () => null, }],


      // these functions are used for permissions, but do not represent a screen
      [fxn.adjuster_licensing      , { noRender : true, ngFxn : 'adjuster-licensing-ng',  wfFxn : 'adjlicense', description : 'Not a Screen - Adjuster Licensing', module  : () =>  null, component : () => null, }],
      [fxn.adjuster_manual_checks  , { noRender : true, ngFxn : 'adjuster-manual-check-confg-ng',  wfFxn : 'adjmanchk', description : 'Not a Screen - Adjuster Manual Checks', module  : () =>  null, component : () => null, }], 
      [fxn.adjuster_activity_types , { noRender : true, ngFxn : 'adjuster-activities-control-ng',  wfFxn : 'ActiCont', description : 'Not a Screen - Adjuster Activity Control', module  : () =>  null, component : () => null, }], 

    ]);
}


export type FunctionInfo = {
  ngFxn  : string,
  wfFxn : string,

  // description for developer purposes, not displayed to user ( basically notes on which screen it is )
  description  : string,

  // file path to module
  module : () => any
 
  // file path to component
  component : () => any

  // true when calendar should render in side menu
  calendar? : boolean ,

  // true when system messages should render at top of component
  messages? : boolean,

  // true when function requires a claim number, eg : claim-summary component only makes sense in the context of a claim number
  claimKey? : boolean ,

  // true when function has not been ported to angular yet
  toDo? : boolean,

  // true when the function is used for permissions and does not represent a menu function
  noRender? : boolean
}

export type FxnCodePlus = {
  fxnCode : string,
  isAngular : boolean
}
