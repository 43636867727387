import { Component, ElementRef } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent, RouterOutlet } from '@angular/router';
import { LoaderService } from '@shared/spinner/loader.service';
@Component({
  selector: 'core-component',
  // template: '<ngx-loading-bar></ngx-loading-bar> <router-outlet></router-outlet>'
  template: '<app-spinner></app-spinner> <router-outlet></router-outlet>'
})
export class CoreComponent {
  constructor(private router:Router, private _load:LoaderService) {

    // this.router.events.subscribe((e:RouterEvent)=>{
    //   this._load.setLoading(false);
    //   if(e.url=="/auth/login") return;
    //   else if(e.url=="/auth/logout" || e.url==undefined || e.url=="/auth/logout-done") return;
    //   else
    //   this.navigationInterceptor(e);
    // })

    if(window.location.pathname.split('/')[1] != 'web-ui'){
      this.router.events.subscribe((e:RouterEvent)=>{

        if (e.url == "/") {
          this.router.navigate(['auth','logout-done']); // Route to "/auth/login"
          return;
        }
        this._load.setLoading(false);
        if(e.url=="/auth/login") return;
        else if(e.url=="/auth/logout" || e.url==undefined || e.url=="/auth/logout-done") return;
        else
        this.navigationInterceptor(e);
      })
    }


  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      //this.loading = true
      this._load.setLoading(true);
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      //this.loading = false
      this._load.setLoading(false);
      // setTimeout(() => {
      // this._load.setLoading(false);
      // }, 2000);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    // if (event instanceof NavigationCancel) {
    //   // this.loading = false
    //   this._load.setLoading(false);
    // }
    // if (event instanceof NavigationError) {
    //   //this.loading = false
    //   this._load.setLoading(false);
    // }
  }


}


