import { Injectable, Injector } from "@angular/core";
import { ApiCredentialsService } from "@core/credentials/api-credentials.service";
import { Subject } from "rxjs";
import { v4 as uuidv4 } from 'uuid';
/** 
 * created to : communicate between different browser windows
 * 
 * note : contains functionality that *may* need to communicate with the parent iFrame to execute */
@Injectable({ providedIn : 'root'})
export class WindowEventsService
{

    static get IsInIFrame() : boolean {
        return ( window as any).parentIFrame != null;
    }

    static CloseWindow () : void{

        if (!this.IsInIFrame) {
            window.close();
            // this usually causes error : Scripts may close only the windows that were opened by them.
            return;
        }

        ( window as any).parentIFrame.sendMessage('close-window');
    }

    /**
     *
     */
    constructor(
        private injector : Injector
    ) {
        this.thisWindowName = window.name = uuidv4(); 
        // this.thisParentWindowName = window.opener?.name;
        this.thisParentWindowName = null;
        this.windowMessageBus = new BroadcastChannel('window-messages');
        this.SubscribeToMessages();
    }

    private thisWindowName : string = '';
    private thisParentWindowName : string = ''
    private windowMessageBus : BroadcastChannel;

    SendMessageToAll( messageType : MessageTypeEnum, messageData : any = null, sendToSelf: boolean = false ){
        this.windowMessageBus.postMessage( <WindowMessage>{
            from : this.thisWindowName,
            to : 'All',
            messageType : messageType,
            messageData :  messageData
        })

        // also send to self!
        if ( sendToSelf ){
            this.OnRecieveMessage( <WindowMessage>{
                from : this.thisWindowName,
                to : 'All',
                messageType : messageType,
                messageData :  messageData
            });
        }


    }

    SendMessageToParent( messageType : MessageTypeEnum, messageData : any = null ){
        this.windowMessageBus.postMessage( <WindowMessage>{
            from : this.thisWindowName,
            to : this.thisParentWindowName,
            messageType : messageType,
            messageData :  messageData
        })
    }

    private SubscribeToMessages(){
        this.windowMessageBus.addEventListener('message', (event) => {
            console.log( 'Recieved Message Message ', event );
            this.OnRecieveMessage(event.data);
        });
        
    }

    private OnRecieveMessage(event : WindowMessage ){
        if ( event.to != 'All' && event.to != this.thisWindowName )
            return;

        console.log( 'Recieved Message to Me ', event );
        if ( event.messageType == MessageTypeEnum.Logout){
            let credentialsService = this.injector.get( ApiCredentialsService);
            credentialsService.logout_fromWindowEventsService();
        }

        if ( event.messageType == MessageTypeEnum.Refresh){
            window.location.reload();
        }

        if ( event.messageType == MessageTypeEnum.PageMessage ){
            this.PageEvents.next( event );
        }
    }

    PageEvents : Subject<WindowMessage> = new Subject<WindowMessage>();

    CloseThisWindow(){
        WindowEventsService.CloseWindow();
    }

}

export enum MessageTypeEnum {
    Refresh = 'Refresh',
    Close = 'Close',
    Logout = 'Logout',
    Other = 'Other',
    PageMessage = 'Page'
}

export type WindowMessage = {
    from : string;
    to : string | 'All';
    messageType :  MessageTypeEnum; 
    messageData : any  ;
}


/**
 TODO

 + change name to WindowService
 + make it injectable, in root
 
    * Call when need to refresh a function in another tab

 var thisWindowId = random string...

public SendMessage( id : windowId, messageType: messageTypeEnum, messageData : any ) : void{

    ... this only refreshed winwo

    // use boradcast channel

}

OnRecieveMessage( ){
    if ( windowId == this.windowId){
        // if refresh && data.fxn == current function
            do refresh the window...
    }

    if (messageType = Logout){
        call new method on token manager to do the logout part!...
    }
}
when refresh windows


// tbd : can use broadcast channel to refresh window with an id!
// maybe do this!  this.logoutBus = new BroadcastChannel('logout-event');
// 
// tbd: send message to window? w/id
// this may need a hook to the layout service because the layout service can refresh a window


*/