export class ErrorResponse implements IErrorResponse {
    message?: string | undefined;
    correlationId?: string | undefined;

    constructor(data?: IErrorResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.message = _data["Message"];
            this.correlationId = _data["CorrelationId"];
        }
    }

    static fromJS(data: any): ErrorResponse {
        data = typeof data === 'object' ? data : {};
        let result = new ErrorResponse();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["Message"] = this.message;
        data["CorrelationId"] = this.correlationId;
        return data; 
    }
}

export interface IErrorResponse {
    message?: string | undefined;
    correlationId?: string | undefined;
}



export class RoutineFailureResponseDto implements IRoutineFailureResponseDto {
    code?: string | undefined;
    message?: string | undefined;
    correlationId?: string | undefined;
    problems?: ProblemDto[] | undefined;
    failureType?: string | undefined;

    constructor(data?: IRoutineFailureResponseDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.code = _data["Code"];
            this.message = _data["Message"];
            this.correlationId = _data["CorrelationId"];
            if (Array.isArray(_data["Problems"])) {
                this.problems = [] as any;
                for (let item of _data["Problems"])
                    this.problems!.push(ProblemDto.fromJS(item));
            }
            this.failureType = _data["FailureType"];
        }
    }

    static fromJS(data: any): RoutineFailureResponseDto {
        data = typeof data === 'object' ? data : {};
        let result = new RoutineFailureResponseDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["Code"] = this.code;
        data["Message"] = this.message;
        data["CorrelationId"] = this.correlationId;
        if (Array.isArray(this.problems)) {
            data["Problems"] = [];
            for (let item of this.problems)
                data["Problems"].push(item.toJSON());
        }
        data["FailureType"] = this.failureType;
        return data;
    }
}

export interface IRoutineFailureResponseDto {
    code?: string | undefined;
    message?: string | undefined;
    correlationId?: string | undefined;
    problems?: ProblemDto[] | undefined;
    failureType?: string | undefined;
}

export class ProblemDto implements IProblemDto {
    code?: string | undefined;
    message?: string | undefined;
    propertyPaths?: string[] | undefined;

    constructor(data?: IProblemDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.code = _data["Code"];
            this.message = _data["Message"];
            if (Array.isArray(_data["PropertyPaths"])) {
                this.propertyPaths = [] as any;
                for (let item of _data["PropertyPaths"])
                    this.propertyPaths!.push(item);
            }
        }
    }

    static fromJS(data: any): ProblemDto {
        data = typeof data === 'object' ? data : {};
        let result = new ProblemDto();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["Code"] = this.code;
        data["Message"] = this.message;
        if (Array.isArray(this.propertyPaths)) {
            data["PropertyPaths"] = [];
            for (let item of this.propertyPaths)
                data["PropertyPaths"].push(item);
        }
        return data;
    }
}

export interface IProblemDto {
    code?: string | undefined;
    message?: string | undefined;
    propertyPaths?: string[] | undefined;
}
