import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { APP_CONFIG, AppConfigFactory } from './app/app.config'
import { registerLicense } from '@syncfusion/ej2-base';


if (environment.production) {
  enableProdMode()
}

let config = AppConfigFactory();

// Registering Syncfusion license key
registerLicense(config.vendor.syncfusionLicenseKey);


platformBrowserDynamic( [ { provide : APP_CONFIG, useValue : config } ])
.bootstrapModule(AppModule)
.catch(err => console.error(err));
