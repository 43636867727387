import { Injectable } from "@angular/core";
import { AppConfig } from "src/app/app.config";
import { AuthenticationClient } from "src/app/system/auth/authentication.client";
import * as dayjs from 'dayjs'; 
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: 'root' })
export class TokenManager {

    constructor(
        private authClient: AuthenticationClient,
        private config :  AppConfig,
        private router : Router,
        private toastr : ToastrService
    ) {
        this.startWfPortal();
        this.listenToWfPortal();
    }

    
    wfPortal: HTMLIFrameElement;
    wfHostDomain: string;

    /* 
        note : try renewal every 5 minutes 
               if browser window is inactive or in background, interval may be throttled down
    */
    interval: number = 5 * 60 * 1000;  //  every 5 minutes
    intervalRef: any;
    renewsRemaining: number = 0; // number of checks to do, until stopping 

    // renewal delay values
    ngRenewalDelayMinutes : number = 20 // minutes
    wfRenewalDelayMinutes : number = 15 // minutes


    stopRenewalRequests() {
        clearInterval(this.intervalRef);
        this.intervalRef = null;
        this.renewsRemaining = 0;
        // console.log('to wf end-session');
        this.wfPortal.contentWindow.postMessage({ 'type': 'end-session' }, this.wfHostDomain);
        localStorage.removeItem('ngRenewAt');
        localStorage.removeItem('wfRenewAt');
    }

    startRenewalRequests() {
        localStorage.removeItem('ngRenewAt');
        localStorage.removeItem('wfRenewAt');
        this.continueRenewalRequests();
    }

    continueRenewalRequests(){
        if ( localStorage.getItem('user')  == null ||
            localStorage.getItem('auth')  == null )
            return;

        this.renewsRemaining = 10; // 10 more renews

        this.tryRenewTokens( true );
        if (this.intervalRef == null && this.renewsRemaining > 0) {
            this.intervalRef = setInterval(() => {
                this.tryRenewTokens();
            },this.interval);
        }
    }


    tryRenewTokens( skipCountdown : boolean = false ): void {
        if ( localStorage.getItem('user')  == null ||
             localStorage.getItem('auth')  == null )
            return;
        
        if ( localStorage.getItem('wfRenewAt') == null )
            this.setupTokenRenewTimes();


        // refresh ngToken
        if ( this.shouldRenewNgToken() ){
            let currentUser    = localStorage.getItem('user');
            let currentNgToken = localStorage.getItem('auth');
            this.authClient.refreshNgAuthToken(currentNgToken, currentUser)
                .subscribe(ngToken => {
                    if ( ngToken == "LOCKED"){
                        //force logout
                        this.toastr.error("User account is locked or invalid")
                        this.router.navigate(['auth','logout']);
                        return;
                    }
                    localStorage.setItem('auth', ngToken);                    
                    let newNgRenewAtTicks = dayjs( new Date() ).add( this.ngRenewalDelayMinutes, 'minutes').toDate().getTime()
                    localStorage.setItem('ngRenewAt', newNgRenewAtTicks.toString() );
                });
        }

        if ( this.shouldRenewWfSession() ){

            // extend or start web forms session
            //console.log('to wf extend-session');
            this.sendMessageAfterIFrameLoad( () =>
                this.wfPortal.contentWindow.postMessage( { 'type': 'extend-session' },  this.wfHostDomain )
            )

        }

        if ( skipCountdown )
            return;

        this.renewsRemaining--;
        if (this.renewsRemaining == 0) {
            clearInterval(this.intervalRef);
            this.intervalRef = null;
        }
    }

    startWfSession(): void {
        let currentUser    = localStorage.getItem('user');
        let currentNgToken = localStorage.getItem('auth');

        if( currentUser == null || currentNgToken == null )
            return;

        this.authClient
            .refreshWebFormsLoginToken(currentNgToken, currentUser)
            .subscribe(wfToken => {
                if ( wfToken == "LOCKED"){
                    //force logout
                    this.toastr.error("User account is locked or invalid")
                    this.router.navigate(['auth','logout']);
                    return;
                }

                //console.log('to wf start-session');

                this.sendMessageAfterIFrameLoad(() => 
                    this.wfPortal.contentWindow
                        .postMessage(
                            { 'type': 'start-session', 'token': wfToken },
                            this.wfHostDomain
                        )
                );

            });
    }

    startWfPortal() {
        let wfPortalUrl = this.config.legacyAppSessionManagmentUrl;
        this.wfPortal = document.getElementById('WebFormsSessionManagmentIFrame') as HTMLIFrameElement;
        this.wfHostDomain = new URL(wfPortalUrl).origin;
        this.wfPortal.src =  wfPortalUrl
    }

    listenToWfPortal() {
        window.addEventListener("message", (event) => {

            if (this.wfHostDomain !== event.origin)
                return;
                
            let m = event.data;
            //console.log('wf portal message', m );

            if (m.type == 'extend-session' && m.result == false){
                this.startWfSession();
            }

            if ( ( m.type == 'extend-session' && m.result == true )|| 
                 ( m.type == 'start-session'  && m.result == true )   ){
                let newWfRenewAtTicks = dayjs( new Date() ).add( this.wfRenewalDelayMinutes, 'minutes').toDate().getTime()
                localStorage.setItem('wfRenewAt', newWfRenewAtTicks.toString() );    
            }

            if ( m.type == 'page-load'){
                this._isIframeReady = true;
            }
        });
    }


    setupTokenRenewTimes() {

        // ng token renewal time - use closest renew time - delay minutes out or sooner
        let newNgRenewAtTicks = dayjs( new Date()).add( this.ngRenewalDelayMinutes, 'minutes').toDate().getTime();
        if ( localStorage.getItem('ngRenewAt') ==  null )
        {
            localStorage.setItem('ngRenewAt', newNgRenewAtTicks.toString() );
        }
        else
        {
            let lsiNgRenewAtTicks = localStorage.getItem('ngRenewAt');
            localStorage.setItem('ngRenewAt', Math.min(newNgRenewAtTicks, +( lsiNgRenewAtTicks ) ).toString());
        }

        // wf session renewal time - keep old if its present, otherwise renew immediately
        if ( localStorage.getItem('wfRenewAt') ==  null )
        {
            let newWfRenewAtTicks = dayjs(new Date()).add(-1, 'minutes').toDate().getTime().toString();
            localStorage.setItem('wfRenewAt', newWfRenewAtTicks.toString() );
        }
    }

    shouldRenewNgToken() : boolean {
        let time = new Date(+localStorage.getItem('ngRenewAt'));
        return time < new Date();
    }

    shouldRenewWfSession() : boolean {
        let time = new Date(+localStorage.getItem('wfRenewAt'));
        return time < new Date();
    }


    sendMessageAfterIFrameLoad( sendMessageCallback ) {

        if( !this._isIframeReady )
        {
            // console.log('waiting for iframe');
            window.setTimeout(() => this.sendMessageAfterIFrameLoad( sendMessageCallback ), 300); 
        } else {
            // note : each message sent causes the iframe content to re-load, due to .aspx postback & internal page logic
            this._isIframeReady = false;
            sendMessageCallback();
            // console.log('message sent');
        }
    }
    _isIframeReady = false;


}
