import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiCredentialsService } from "./api-credentials.service";


@Injectable()
export class ApiAuthenticator implements HttpInterceptor {
    constructor(private apiCredentials: ApiCredentialsService) {}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        var credentials = this.apiCredentials.username + ":" + this.apiCredentials.ngAuthToken;
        var correlationId = this.apiCredentials.correlationId;
        var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const headers = {
            'X-Api-Credentials': credentials,
            'X-Correlation-ID' : correlationId,
            'X-TimeZone' : timezone
          };
          
        const authReq = req.clone({ setHeaders : headers });

        return next.handle(authReq);
    }

}
