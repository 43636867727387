import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  private totalRequests = 0;

  constructor(
    private loadingService: LoaderService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let isLogin = request.url.includes("authentication");
    if (isLogin) {
      return next.handle(request).pipe(
        finalize(() => {
          this.loadingService.setLoading(false);
        })
      );
    }
    else if (request.body != null && request.url.includes("draft") && typeof (JSON.parse(request?.body)) == "object") {
      return next.handle(request).pipe(
        finalize(() => {
          this.loadingService.setLoading(false);
        })
      );
    }
    else {
      this.totalRequests++;
      this.loadingService.setLoading(true);
      return next.handle(request).pipe(
        finalize(() => {
          this.totalRequests--;
          if (this.totalRequests == 0) {
            this.loadingService.setLoading(false);
          }
        })
      );
    }

    //this.loadingService.requestStarted();

    //commented for global api call
    // this.loadingService.setLoading(true);
    // return this.handler(next,request);

  }

  handler(next, request) {
    //this.totalRequests++;
    return next.handle(request)
      .pipe(
        tap(
          (event) => {
            if (event instanceof HttpResponse) {
              //this.loadingService.requestEnded();
              setTimeout(() => {
                this.loadingService.setLoading(false);
              }, 1500);
            }
          },
          (error: HttpErrorResponse) => {
            //this.loadingService.resetLoader();
            this.loadingService.setLoading(false);
            throw error;
          }
        ),
        // finalize(() => {
        //   this.totalRequests--;
        //   if (this.totalRequests == 0) {
        //     this.loadingService.setLoading(false);
        //     this.loadingService.requestEnded();
        //   }
        // })
      )
  }

}