import { Injectable } from "@angular/core";
import { ApiCredentialsService } from "@core/credentials/api-credentials.service";
import { Observable, pipe, ReplaySubject } from "rxjs";
import { first, map, shareReplay } from 'rxjs/operators'; 
import { AppRouteMaps, fxn } from "src/app/app-routing-map";
import { UserClient, UserDto, UserPermissions} from "./user-client.service";

@Injectable({ providedIn: 'root'})
export class UserService {
  
    constructor(private userService : UserClient, private apiCreds : ApiCredentialsService ) {}    

    private permissions : { [key : string] : ReplaySubject<UserPermissions> } = {};
    private userDataO : ReplaySubject<UserData> = new ReplaySubject<UserData>(1);
    private isUserDataLoaded : boolean = false;
    private isUserDataLoading : boolean = false;

    GetUserApiCorrelationId(){
        return this.apiCreds.correlationId;
    }

    /** Cache buster - clears User Data, User function permissions */
    ClearCache(){
        // store observables locally
        let lPermissions = this.permissions;
        let lUserDataO = this.userDataO;

        // reset class observables
        this.permissions = {};
        this.isUserDataLoaded = false;
        this.isUserDataLoading = false;
        this.userDataO = new ReplaySubject<UserData>(1);
        
        // destroy current observable values
        let pKeys = Object.keys(this.permissions)
        for(let key of pKeys){ 
            let obsPermissions = lPermissions[key];
            obsPermissions.complete();
            obsPermissions.unsubscribe();
            obsPermissions = null;
        }
        lUserDataO.complete();
        lUserDataO.unsubscribe();
        lUserDataO = null;
    }

    // private userDataObs : .. user data as ReplaySubject...?
    GetUserData() : Observable<UserData>{

        if ( this.isUserDataLoaded || this.isUserDataLoading ){
            return this.userDataO.pipe( first() );
        }
        
        this.userService.getUserInfo()
            .pipe( 
                map(( user: UserDto) => { 
                    let userData = new UserData();
                    userData.username = user.userId;
                    userData.userRealName = user.userRealName;
                    userData.teamCode = user.teamCode;
                    userData.language = user.language;
                    userData.isTeamLeader = user.isTeamLeader;
                    userData.userEmail = user.userEmail;
                    userData.userPhoneNumber = user.userPhoneNumber;
                    return userData;
                })
            ).subscribe(
                data => {
                    this.userDataO.next( data )
                    this.userDataO.complete();
                    this.isUserDataLoaded = true;
                }
            );
        this.isUserDataLoading = true;

        return this.userDataO;
    }


    GetUserPermissions(  fxn : fxn ) : Observable<UserPermissions>{
        let f = AppRouteMaps.WhatFxnToRender( fxn );
        return this.GetUserPermissionsForFunctionCode( f.fxnCode );
    }

    GetUserPermissionsForFunctionCode(  fxnCode : string ) : Observable<UserPermissions>{
        if ( this.permissions[fxnCode] != null )
            return this.permissions[fxnCode].pipe(first(),  shareReplay(1));

        this.permissions[fxnCode] = new ReplaySubject<UserPermissions>(1);

        this.userService.getUserPermissions( fxnCode ).subscribe(
            { next : (data) => {
                this.permissions[fxnCode].next( data )
                this.permissions[fxnCode].complete();
            }, error : () => {
                this.permissions[fxnCode] = null; //reset so will load the permissions next time
            }});

        return this.permissions[fxnCode];
    }

   
}

export class UserData {
    username:string;
    userRealName:string;
    teamCode : string;
    language: string;
    isTeamLeader : boolean;
    userEmail: string;
    userPhoneNumber: string;
}


