import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  showLoader=false;

  constructor(
    public loader: LoaderService, 
    private cdRef:ChangeDetectorRef) 
   {
   }

  ngOnInit(): void {
    //this.init();
  }


   init(){
    this.loader.getLoaderObserver().subscribe((res)=>{
      this.showLoader = (res=="start");
      this.cdRef.detectChanges();
    });
   }


}