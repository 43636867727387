<!--1st loader sample-->
<!-- <div *ngIf="this.loader.getLoading()" class="cssload-container">
    <div class="cssload-speeding-wheel"></div>
</div> -->
<!--End of 1st loader sample-->

<!--2nd loader sample-->
<div *ngIf="this.loader.getLoading()" class="cssload-container">
    <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<!--End of 2nd loader sample-->